import ebrelle_dots_cell from "../assets/e1 (5).jpg"
import ebrelle_right_view from "../assets/e1 (4).jpg"
import ebrelle_left_view from "../assets/e1 (3).jpg"
import ebrelle_leftangle_view from "../assets/e1 (6).jpg"
import ebrelle_rightangle_view from "../assets/e1 (7).jpg"

import "./Gallery.css";

export const Gallery = () => {
  return (
    <div className="gallery">
        <div>
            <h1>Gallery</h1>
        </div>
        <div className="img-gallery">
            <img src={ebrelle_right_view} alt={ebrelle_right_view}/>
            <img src={ebrelle_dots_cell} alt={ebrelle_dots_cell}/>
            <img src={ebrelle_left_view} alt={ebrelle_left_view}/>
            <img src={ebrelle_leftangle_view} alt={ebrelle_leftangle_view}/>
            <img src={ebrelle_rightangle_view} alt={ebrelle_rightangle_view}/>
        </div>
    </div>
  )
}
