import "./Testimonydata.css"

export const Testimonydata = (props) => {
  return (
    <>
        <div className={props.className}>
            <div className="test-text">
                <h2>{props.heading}</h2>
                <p>"{props.text}"</p>
                <p>{props.author}</p>
            </div>
            <div className="test-image">
                <img src={props.img1} alt={props.img1}/>
            </div>
        </div>

    </>
  )
}
