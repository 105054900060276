import NavbarAdvance from '../components/NavbarAdvance'
import { Footer } from "../components/Footer";
import { Testimonydata } from "../components/Testimonydata";

import ebrelle_firash_testimony from "../assets/ebrelle_firash.gif";
import ebrelle_shafiq__testimony from "../assets/ebrelle_shafiq1.gif";
import ebrelle_sumairi__testimony from "../assets/ebrelle_sumairi.gif";
import { Ebrelleuserdata } from "../components/Ebrelleuserdata";

function Testimony() {
  return (
    <div>
      <NavbarAdvance/>
      <Testimonydata
        heading="Blind Student"
        text="eBrelle Laptop is very friendly. Firash (7 years old) 
            was able to become familiar with the user-interface in less than an hour.
            He loves it because it is easy to use and fun."
        author="(Bunah, Firash's caretaker)"
        img1={ebrelle_firash_testimony}
        className="first-test"
      />
      <Testimonydata
        heading="University Student"
        text="eBrelle Laptop helps me to study in a more effective and efficient manner.
        Now, I could read the lectures' notes using the pendrive.
        I also can use the eBrelle laptop to take note in the class. 
        I don't have to bring the heavy Braille machine anymore. 
        I'm happy that my grades have improved significantly."
        author="(Syafiq, Universiti Malaya)"
        img1={ebrelle_shafiq__testimony}
        className="first-test-reverse"
      />
      <Testimonydata
        heading="Blind Quran Teacher"
        text="eBrelle really makes my life easier. 
        I don't have to bring all 6 volumes of Braille Quran anymore or scared
         if I brought the wrong volume when I go to teach the Braille Quran to my students.
         May those who work on this project be blessed by Allah SWT."
        author="(Ustaz Sumairi, Braille Quran teacher)"
        img1={ebrelle_sumairi__testimony}
        className="first-test-third"
      />
      
      <Ebrelleuserdata/>

      <Footer />
    </div>
  )
}

export default Testimony