import { useState } from "react"
import "./Listlevel2.css"

function Listlevel2({listout, verse, level1_selected}) {
  const [showAyah, setShowAyah] = useState("")

  const selected = (data) =>{
    level1_selected(data.text, data.brailleText, data.textType);
    setShowAyah(data.text);
  }
  
  return (
    <div>
        {
             <ol className='listlevel2-container'>
             {
               listout[`${verse}`].map((data, index)=>{
                 return(
                   <li className="listlevel2-body"
                       key ={index}>
                       
                       <button className="listlevel2-button" onClick={() => selected(data)}>
                       {/* <button className="listlevel2-button" onClick={() => level1_selected(data.text, data.brailleText)}> */}
                         {data.subChpName} <br />
                       </button>
                       <div className="listlevel2-text">{data.text}</div>
                   </li>
                 )
 
               })
             }
             </ol>
        }

    </div>
  )
}

export default Listlevel2