import React, { useState } from 'react'
import "./Breadcrumbs.css"

function Breadcrumbs({crumbs, selected}) {

  function isLast(index){
    return index === crumbs.length - 1;
  }

  return (
    <div>
      <ol className="breadcrumb-ol">
          {
            crumbs.map((crumb, index) => {
              const disableded = isLast(index) ? 'disabled': '';

              return(
                  <li
                      key={index}
                      className="breadcrumb-container"
                  >
                    <button 
                      className = {disableded ? "button-breadcrumbs-disable":"button-breadcrumbs"} onClick={() => selected(crumb)}
                      disabled={disableded}
                    >
                    {crumb}  
                    {
                      disableded ? "" : " >"
                    }
                    </button>

                  </li>
              );
            })
          }
      </ol>
    
    </div>
  )
}

export default Breadcrumbs