import { useRef } from "react";
import "./ContactData.css"
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

export const ContactData = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_yk1yqgl', 'template_t4o8xvh', form.current, 'mB3KJ-84ypzNOyiLu')
      .then((result) => {
          console.log(result.text);
          console.log("Message sent");
          toast.success('Message sent!')
      }, (error) => {
          console.log(error.text);
          toast.error('Message NOT sent!')
      });
  };

  
  return (
    <div className="from-container">
        <Toaster />
        <br />
        <h1>For quotation or any inquiry</h1>
        <h1>Whatapp us at: <a href="https://api.whatsapp.com/send?phone=60182574520">+6018-2574520</a></h1>
        <h1>Or</h1>
        <h1>Send a message to us!</h1>
        <form ref={form} onSubmit={sendEmail}>
            <input type="text" placeholder="Name" name="name" />
            <input type="text" placeholder="Email" name="email" />
            <input type="text" placeholder="Subject/Title" name="subject" />
            <textarea placeholder="Message" rows="1" name="message"></textarea>
            <button type="submit" value="Send">Send Message</button>
        </form>
    </div>
  )
}
