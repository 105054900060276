import "./LoginSoftware.css"
import { useRef, useEffect, useState } from "react";

function LoginSoftware({Login, errorUsername, roomAvailable}) {

  const [selectedRoom, setselectedRoom] = useState(roomAvailable[0]);
  
  const form = useRef();
  
  const [details, setDetails] = useState(
    {
        username: "",
        password: ""
    }
  )
  
  const [classoi, setClassoi] = useState("Room 1")

  useEffect(() => {
    console.log("IKAN");
    console.log("IKAN");
    console.log("IKAN");
    console.log(roomAvailable);
    setClassoi(roomAvailable[0])
    
  }, [roomAvailable]);
  
  const startClass = (e) => {
    e.preventDefault();
    if (details.password.length !== 0 && details.username.length !== 0) {
      Login(details, classoi)
    }     
  }
  
  return (
    <div className="login-container">
       <h1>eBrelle</h1>
       <p>Online Teaching Class Room</p>
        
        <form ref={form} onSubmit={startClass}>
            <input type="text" value={details.username} onChange={(e) => setDetails({...details, username:e.target.value})} placeholder="Your Name" name="name" />
            <br />
            <input type="text" value={details.password} onChange={(e) => setDetails({...details, password:e.target.value})} placeholder="Class Password" name="password" />
            <br />
            
            <select
              value={classoi}
              onChange={(e) => setClassoi(e.target.value)}
            >
            {/* <option value="Room 1">Room 1</option>
            <option value="Room 2">Room 2</option>
            <option value="Room 3">Room 3</option> */}
            
              {
                roomAvailable.map((name,index) => (
                        <option value={roomAvailable[index]}>{roomAvailable[index]}</option>
                        
                ))
              }
                    
            </select>

            <br />
            <button type="submit">Start Class</button>
            {
              (errorUsername !== "") ? <div className="errorUsername">{errorUsername}</div>: ""
            }
        </form>
    </div>
  )
}

export default LoginSoftware