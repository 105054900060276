import "./Ebrelleuserdata.css"

import ebrelle_user_qusairy from "../assets/ebrelle_user1.jpeg"
import ebrelle_user_iman from "../assets/ebrelle_user2.jpeg"
import ebrelle_user_singapore from "../assets/ebrelle_user3.jpeg"
import ebrelle_user_rafique from "../assets/ebrelle_user4.jpeg"
import ebrelle_user_ec from "../assets/ebrelle_user5.jpeg"
import ebrelle_user_afifah from "../assets/ebrelle_user6.jpeg"
import ebrelle_user_kuching from "../assets/ebrelle_user7.jpeg"
import ebrelle_user_kuching_2 from "../assets/ebrelle_user8.jpeg"
import ebrelle_user_kuching_3 from "../assets/ebrelle_user9.jpeg"
import ebrelle_user_kuching_4 from "../assets/ebrelle_user10.jpeg"
import ebrelle_user_universiti from "../assets/ebrelle_user11.jpeg"
import ebrelle_user_rizan from "../assets/ebrelle_user12.jpeg"
import ebrelle_user_syed from "../assets/ebrelle_user13.jpeg"
import ebrelle_user_kpm from "../assets/ebrelle_user14.jpeg"
import ebrelle_user_azhar from "../assets/ebrelle_user15.jpeg"
import ebrelle_user_wan_fadil from "../assets/ebrelle_user16.jpeg"
import ebrelle_user_asyid from "../assets/ebrelle_user17.jpeg"
import ebrelle_user_firdaus from "../assets/ebrelle_user18.jpeg"
import ebrelle_user_zakaria from "../assets/ebrelle_user19.jpeg"
import ebrelle_user_danial from "../assets/ebrelle_user21.jpeg"
import ebrelle_user_amirudin from "../assets/ebrelle_user22.jpeg"
import ebrelle_user_ssb from "../assets/ebrelle_user23.jpeg"

export const Ebrelleuserdata = () => {
  return (
    <div className="ebrelle-user">
        <div>
            <h1>eBrelle Users</h1>
        </div>
        <div className="img-user">
            <img src={ebrelle_user_qusairy} alt={ebrelle_user_qusairy}/>
            <img src={ebrelle_user_iman} alt={ebrelle_user_iman}/>
            <img src={ebrelle_user_singapore} alt={ebrelle_user_singapore}/>
            <img src={ebrelle_user_rafique} alt={ebrelle_user_rafique}/>
            <img src={ebrelle_user_ec} alt={ebrelle_user_ec}/>
            <img src={ebrelle_user_afifah} alt={ebrelle_user_afifah}/>
            <img src={ebrelle_user_kuching} alt={ebrelle_user_kuching}/>
            <img src={ebrelle_user_kuching_2} alt={ebrelle_user_kuching_2}/>
            <img src={ebrelle_user_kuching_3} alt={ebrelle_user_kuching_3}/>
            <img src={ebrelle_user_kuching_4} alt={ebrelle_user_kuching_4}/>
            <img src={ebrelle_user_universiti} alt={ebrelle_user_universiti}/>
            <img src={ebrelle_user_rizan} alt={ebrelle_user_rizan}/>
            <img src={ebrelle_user_syed} alt={ebrelle_user_syed}/>
            <img src={ebrelle_user_kpm} alt={ebrelle_user_kpm}/>
            <img src={ebrelle_user_azhar} alt={ebrelle_user_azhar}/>
            <img src={ebrelle_user_wan_fadil} alt={ebrelle_user_wan_fadil}/>
            <img src={ebrelle_user_asyid} alt={ebrelle_user_asyid}/>
            <img src={ebrelle_user_firdaus} alt={ebrelle_user_firdaus}/>
            <img src={ebrelle_user_zakaria} alt={ebrelle_user_zakaria}/>
            <img src={ebrelle_user_danial} alt={ebrelle_user_danial}/>
            <img src={ebrelle_user_amirudin} alt={ebrelle_user_amirudin}/>
            <img src={ebrelle_user_ssb} alt={ebrelle_user_ssb}/>         
        </div>
    </div>
  )
}
