import NavbarAdvance from '../components/NavbarAdvance'
import LoginSoftware from '../components/LoginSoftware'
import "./OnlineTeaching.css"
import { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard'
import ScrollBar from '../components/ScrollBar';
import axios from "axios";

function OnlineTeaching() {
  //api untuk Express
  let api_link = "https://node2.ebrelle.com/";
  //let api_link = "http://localhost:3003/";

  const all_room = ['room1', 'room2', 'room3', 'room4', 'room5', 'room6', 'room7', 'room8', 'room9', 'room10'];
  const display_room = ['Room 1', 'Room 2', 'Room 3', 'Room 4', 'Room 5', 'Room 6', 'Room 7', 'Room 8', 'Room 9', 'Room 10'];
  
  const [roomAvailable, setRoomAvailable] = useState([])

  useEffect(() => {
    check_room()
    
  }, []);

  const check_room = async () => {
    let combineRoom = []
    let response = ''
    for (let i = 0; i < all_room.length; i++) {
      response = await axios.get(api_link + all_room[i])
      if(response.data.length === 0){
        combineRoom.push(display_room[i])
      }
    }

    // let response = await axios.get(api_link + 'room1')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 1')
    // }
    // response = await axios.get(api_link + 'room2')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 2')
    // }
    // response = await axios.get(api_link + 'room3')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 3')
    // }
    // response = await axios.get(api_link + 'room4')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 4')
    // }
    // response = await axios.get(api_link + 'room5')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 5')
    // }
    // response = await axios.get(api_link + 'room6')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 6')
    // }
    // response = await axios.get(api_link + 'room7')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 7')
    // }
    // response = await axios.get(api_link + 'room8')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 8')
    // }
    // response = await axios.get(api_link + 'room9')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 9')
    // }
    // response = await axios.get(api_link + 'room10')
    // if(response.data.length === 0){
    //    combineRoom.push('Room 10')
    // }
    setRoomAvailable([...combineRoom])
    // console.log(roomAvailable);
  }
  const [user, setUser] = useState({
    name: "",
    password: "",
    classroom: ""
  });
  
  const [errorUsername, setErrorUsername] = useState("");
  
  const Login = async (details, classoi) => {
    
    //http://localhost:3003/password/1234
    let response = await axios.get(api_link + 'password/' + details.password)
    console.log(response.data.length);
    console.log(response.data);

    // console.log(details, classoi);

    // setUser({
    //   name: details.username,
    //   password: details.password,
    //   classroomOi: classoi
    // });
    
    // console.log(user.classroomOi);
    // setErrorUsername("");

    if (response.data.length !== 0) {
      let found_double = ''
      for (let i = 0; i < all_room.length; i++) {
        response = await axios.get(api_link + 'check/' + details.password + '/' + all_room[i])
        console.log(response.data.length);
        if(response.data.length === 1){
          found_double = all_room[i]
          console.log(i);
          break
        }
      }
      // console.log("BABY BABY");
      // console.log(found_double); 
      if (found_double === ''){
        localStorage.setItem("user", JSON.stringify(details.username));
        console.log("You have logged in");
        setUser({
          name: details.username,
          password: details.password,
          classroomOi: classoi
        });
        setErrorUsername("");
      }
      else {
        console.log(`Class Password has been used at ${found_double}`);
        setErrorUsername(`Class Password has been used at ${found_double}`);
      }
    } 
    else {
      console.log("Class Password is Wrong !!!");
      setErrorUsername("Class Password is Invalid!");
    }
  };

  const Logout = () => {
    setUser({
      name: "",
      password: "",
      classroom:""
    });
    setErrorUsername("");
    check_room()
  };

  return (
    <div className='body-online'>
        
        {user.name === "" ? <NavbarAdvance/> : ""}
        {user.name === "" ? <LoginSoftware Login={Login} errorUsername={errorUsername} roomAvailable = {roomAvailable} /> : <Dashboard Logout={Logout} roomSelect={user.classroomOi} username = {user.name} clspassword = {user.password}/>}
        {/* {user.name !== "" ? <ScrollBar/> : <Dashboard Logout={Logout} roomSelect={user.classroom} username = {user.name}/>} */}
    </div>
  )
}

export default OnlineTeaching