import { useRef, useEffect, useState } from "react";
import "./Dashboard.css";
import "./NavbarClassRoom.css";

import Breadcrumbs from "./Breadcrumbs";
import Listlevel1 from "./Listlevel1";
import Listlevel2 from "./Listlevel2";
import quranData from "../data/alQuranEbrailleToBeChecked.json"
import iqra1Data from "../data/Iqra_1.json"
import iqra2Data from "../data/Iqra_2.json"
import iqra3Data from "../data/Iqra_3.json"
import iqra4Data from "../data/Iqra_4.json"
import iqra5Data from "../data/Iqra_5.json"
import iqra6Data from "../data/Iqra_6.json"
import bahasaMelayuData from "../data/BahasaMelayuT6.json"
import sejarahData from "../data/sejarahT6.json"
import ListModules from "./ListModules";

import io from 'socket.io-client';
import axios from "axios";

// api untuk socket io
const socket = io.connect("https://node.ebrelle.com/");  // when open the browser make sure you use https to http
//const socket = io.connect("http://192.168.1.9:3000/"); //please comment https in html file

// api untuk Express 
//let api_link = "http://localhost:3003/";
let api_link = "https://node2.ebrelle.com/";


let receivedText = []
let receivedTime = []


function Dashboard({Logout, roomSelect, username, clspassword}) {
  const [modules, setModules] = useState(['Modules'])
  const [level,  setLevel] = useState(0)
  const [listModule, setListModule] = useState(['Al-Quran', 'Iqra 1', 'Iqra 2', 'Iqra 3', 'Iqra 4', 'Iqra 5', 'Iqra 6',  'Bahasa Melayu', 'Sejarah'])
  const [nextObject, setNextObject] = useState("")
  const [displayText, setDisplayText] = useState([])
  const [messBraille, setMessBraille] = useState("");
  const [inBraille, setInBraille] = useState(true);
  
  const [dataSelect, setDataSelect] = useState([])

  const blackboardsRef = useRef(null);
  useEffect(() => {
    if (blackboardsRef.current) {
      blackboardsRef.current.scrollTop = blackboardsRef.current.scrollHeight;
    }
  }, [displayText]);

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    const alertUser = (e) => {
      // e.preventDefault();
      setInputValue('out')
      e.returnValue = "";
      return ''
    };
  
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [inputValue]);
  
 
  const [message, setMessage] = useState("");
  const [room_name, setRoomName] = useState(roomSelect);
  
  // const [studentList, setStudentList] = useState(['eBrelle 1', 'eBrelle 2', 'eBrelle 3', 'eBrelle 4', 'eBrelle 1', 'eBrelle 2', 'eBrelle 3', 'eBrelle 4']);
  const [studentList, setStudentList] = useState([]);
  
  const [join, setJoin] = useState(true)
  
  const attendeesRef = useRef(null);
  useEffect(() => {
    if (attendeesRef.current) {
      attendeesRef.current.scrollTop = attendeesRef.current.scrollHeight;
    }
  }, [studentList]);


  const selected = (crumb) => {
    console.log(crumb);
    console.log(level);
    let index = modules.indexOf(crumb);
    console.log(index);

    if (level > 0){
      setLevel(index)
      let x = 0
      while (x < index){ 
        modules.pop()
        x = x + 1
      }
      setModules(modules)
      if(index === 0){
        setModules([])
        setModules(['Modules'])
      }
    }
  }

  const level1_selected = (name) => {
    console.log("LEVEL 1");
    console.log(name);
    setModules([...modules, name])
    if (name === 'Al-Quran'){
      setDataSelect(quranData)
    }
    else if (name === 'Iqra 1'){
      setDataSelect(iqra1Data)
    }
    else if (name === 'Iqra 2'){
      setDataSelect(iqra2Data)
    }
    else if (name === 'Iqra 3'){
      setDataSelect(iqra3Data)
    }
    else if (name === 'Iqra 4'){
      setDataSelect(iqra4Data)
    }
    else if (name === 'Iqra 5'){
      setDataSelect(iqra5Data)
    }
    else if (name === 'Iqra 6'){
      setDataSelect(iqra6Data)
    }
    else if (name === 'Bahasa Melayu'){
      setDataSelect(bahasaMelayuData)
    }
    else if (name === 'Sejarah'){
      setDataSelect(sejarahData)
    }
    
    setLevel(1)
    console.log(level); 
  }
  const level2_selected = (name) => {
    console.log("LEVEL 2");
    console.log(name);
    setModules([...modules, name])
    setLevel(2)
    console.log(level);
    setNextObject(name)
  }
  const level3_selected = (text, brailleText, textType) => {
    console.log(text);
    console.log(brailleText);
    console.log(textType);
    receivedText.push(text)
    setDisplayText([...receivedText])

    const currTime = new Date().toLocaleTimeString();
    receivedTime.push(currTime)
    console.log(receivedText, receivedTime);
    //setMessage(brailleText);
    //socket.emit('sendToRpi', { roomId: room_name, message });

    console.log(modules[1]);
    socket.emit('sendBrailleToRpi', { roomId: room_name, brailleText, textType});
    
  }

  const [listpanel, setListPanel] = useState([]);
  const [listmessage, setListMsg] = useState([]);
  // const [listpanel, setListPanel] = useState(["eBrelle 1",
  //                                             "eBrelle 2",
  //                                             "eBrelle 2",
  //                                             "eBrelle 1",
  //                                             "eBrelle 1",
  //                                             "eBrelle 1",
  //                                             "eBrelle 1",
  //                                             "eBrelle 1",
  //                                             "eBrelle 1",
  //                                           ]);
  // const [listmessage, setListMsg] = useState(["Hai, I am eBrelle 2, nice to meet you",
  //                                             "Nice to meet you",
  //                                             "Hai, I am eBrelle 2, nice to meet you in this class",
  //                                             "Hai, I am eBrelle 2, nice to meet you",
  //                                             "Hai, I am eBrelle 2, nice to meet you",
  //                                             "Hai, I am eBrelle 2, nice to meet you",
  //                                             "Hai, I am eBrelle 2, nice to meet you",
  //                                             "Hai, I am eBrelle 2, nice to meet you",
  //                                             "Hai, I am eBrelle 2, nice to meet you",
  //                                             ]);

  let panelChat = []
  let panelMsg = []
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom when component mounts or updates
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [listpanel]);    // change to listpanel
 
  useEffect(() => {
   
    setInBraille(false)

    if (join === true){
      joinRoom()
      setJoin(false)
    }
    const update_list = (data) => {
      console.log(data[room_name]);
      setStudentList(data[room_name].filter(id => id !== username))
    };

    const receive_msg = (data) => {     
      panelChat.push(data.username)
      panelMsg.push(data.message)
      console.log(panelChat, panelMsg);
      setListPanel([...panelChat])
      setListMsg([...panelMsg])
    };
    // const teacher_join = async (data) => {     
      
    //   console.log(data);
    //   let response = await axios.get('http://localhost:3000/insertroom')
    //   //console.log(response)
    //   console.log(response.data)
    //   console.log(data);
      
    // };

    // const teacher_join = async () =>{
    //   let response = await axios.get('https://jsonplaceholder.typicode.com/users/')
    //   //console.log(response)
    //   console.log(response.data)
    //   setData(response.data)
    //   console.log(data);
    // }
  
    socket.on('update_listpanel', update_list);
    socket.on('messageFromRpi', receive_msg);
    //socket.on('teacherJoined', teacher_join);

    return () => {
      socket.off('update_listpanel', update_list);// Otherwise you'll start getting errors when the component is unloaded   
      socket.off('messageFromRpi', receive_msg);
      //socket.on('teacherJoined', teacher_join);
    }
  }, []);

  const joinRoom = async() => {
    let roomDatabase = ''
    //socket.emit('joinRoom', {roomId:room_name, username:username});
    if (username.trim() !== '') {
      socket.emit('joinRoom', {roomId:room_name, username:username});
      //response = await axios.get('http://localhost:3000/insertroom')
      console.log(room_name);
      console.log("NOOOOO");
      console.log("NOOOOO");

      if(roomSelect === 'Room 1'){
        roomDatabase = 'room1'
      }
      else if(roomSelect === 'Room 2'){
        roomDatabase = 'room2'
      }
      else if(roomSelect === 'Room 3'){
        roomDatabase = 'room3'
      }
      else if(roomSelect === 'Room 4'){
        roomDatabase = 'room4'
      }
      else if(roomSelect === 'Room 5'){
        roomDatabase = 'room5'
      }
      else if(roomSelect === 'Room 6'){
        roomDatabase = 'room6'
      }
      else if(roomSelect === 'Room 7'){
        roomDatabase = 'room7'
      }
      else if(roomSelect === 'Room 8'){
        roomDatabase = 'room8'
      }
      else if(roomSelect === 'Room 9'){
        roomDatabase = 'room9'
      }
      else if(roomSelect === 'Room 10'){
        roomDatabase = 'room10'
      }
      let response = await axios.get(api_link + 'insertroom/' + username + '/' + clspassword + '/' + roomDatabase)
      console.log(response.data);
      console.log("HAHAHAHA");
      
      response = await axios.get(api_link + 'room_recorded/' + username + '/' + clspassword + '/' + roomDatabase)
      
      
      
    }
  };
  
  const sendMessage = () =>{
    if (message.trim() !== '') {
      socket.emit('sendToRpi', { roomId: room_name, message, inBraille});
      receivedText.push(message)
      setDisplayText([...receivedText])
      const currTime = new Date().toLocaleTimeString();
      receivedTime.push(currTime)
      console.log(receivedText, receivedTime);
      console.log(displayText);
      
      //setMessage('');

    }
  }

  const logoutHandler = async() => {
    let roomDatabase = ''

    if(roomSelect === 'Room 1'){
      roomDatabase = 'room1'
    }
    else if(roomSelect === 'Room 2'){
      roomDatabase = 'room2'
    }
    else if(roomSelect === 'Room 3'){
      roomDatabase = 'room3'
    }
    else if(roomSelect === 'Room 4'){
      roomDatabase = 'room4'
    }
    else if(roomSelect === 'Room 5'){
      roomDatabase = 'room5'
    }
    else if(roomSelect === 'Room 6'){
      roomDatabase = 'room6'
    }
    else if(roomSelect === 'Room 7'){
      roomDatabase = 'room7'
    }
    else if(roomSelect === 'Room 8'){
      roomDatabase = 'room8'
    }
    else if(roomSelect === 'Room 9'){
      roomDatabase = 'room9'
    }
    else if(roomSelect === 'Room 10'){
      roomDatabase = 'room10'
    }
    
    setLoginUser("");
    localStorage.clear();
    //socket.disconnect();
    socket.emit('leaveRoom', {roomId:room_name, username:username})
    //let response = await axios.get('http://localhost:3000/deleteroom')
    let response = await axios.get(api_link + 'deleteroom/' + roomDatabase)
      
    Logout();
  };

  const [loginUser, setLoginUser] = useState(() => {
    const localData = localStorage.getItem("user");
    return localData ? JSON.parse(localData) : "";
  });

  const [state, setState] = useState(false);

  const menuClick = () => {
    setState(!state);
  };

  const [showAttend, setShowAttend] = useState(true);

  const showAttendees = () => {
    setShowAttend(false);
  }
  const showChat = () => {
    setShowAttend(true);
  }
  return (
    <>
      <nav className="nav-container">
        <div className="navbar-title">
          <h1>
            eBrelle
            <i>
              <sub>Laptop for the blind</sub>
            </i>
          </h1>
        </div>
        <div className="menu-icons" onClick={menuClick}>
          <i className={state ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={state ? "nav-menu active" : "nav-menu"}>
          <li>
            <div className="icon-user">
              <i className="fa-solid fa-circle-user"></i>
            </div>
            <div className="name-user">
              {username}
              {/* {"Room 1"} */}
            </div>

            {/* <div className="login-user">
              {username}
            </div> */}
          </li>
          <li>
            <div className="icon-user">
              <i className="fa-solid fa-users-rectangle"></i>
            </div>
            <div className="name-user">
              {roomSelect}
              {/* {"Room 1"} */}
            </div>
          </li>
          {/* <li>
            {
              showAttend ? (<div className="nav-links" onClick={showAttendees}><i className="fa-solid fa-users"></i>Attendees</div>):
              (<div className="nav-links" onClick={showChat}><i className="fa-regular fa-message"></i>Chat</div>)
            }          
          </li> */}
         
          <li>
            <div onClick={logoutHandler} className="nav-links" tabindex="0">
              <i className="fa-solid fa-right-from-bracket"></i>
              Logout
            </div>
          </li>
        </ul>
      </nav>
      {
            inputValue === 'out'? logoutHandler():''
      }
      <div div className="dashboard-container">
        
        <div className="module-flex">  
            <div className="breadcrumb-flex">
                <Breadcrumbs crumbs = {modules} selected = {selected}/>
            </div>
            <div className="listed-flex">
              
              {
                level === 0? <ListModules listout = {listModule} level = {level} level1_selected = {level1_selected}/>:""
              }
              
              {
                level === 1? <Listlevel1 listout = {dataSelect} level1_selected = {level2_selected}/>:""
              }
              {
                level === 2? <Listlevel2 listout = {dataSelect} verse = {nextObject} level1_selected = {level3_selected}/>:""
              }
        
            </div>
        </div>
        
        <div className="blackboard-flex">
            <h2 className="blackboard-font">Braille Class</h2>
            <div className="displayed-flex" ref={blackboardsRef}>
              { 
                    displayText.map((name,index) => (
                        <div className="ayah-flex" key={index}>
                          {name} <br />
                          <div className="time-flex">{receivedTime[index]}</div>
                          {/* <div> {receivedText[index]}</div> */}
                        </div>  
                    ))
                    
              } 
            </div>
            <form 
              onSubmit={e => {
                e.preventDefault();
                sendMessage()
              }}
            >
              <div className="sentmessage-flex">
                <textarea className="message-flex" rows="1" placeholder="Message" name="message" value={message}
                    onKeyDown={(e) =>{
                      if(e.key === "Enter" && e.shiftKey === false) {
                        e.preventDefault();
                        sendMessage()
                      }
                      else if(e.key === "Enter" && e.shiftKey === true) {
                        setMessage(e.target.value)
                      }                    
                    }}
                    onChange={(e) => {
                      setMessage(e.target.value)
                    }}
                    />
                {/* <button className="button-flex" onClick={sendMessage}>Send</button> */}
                <button type="submit" className="button-flex">Send</button>
              </div>
            </form>
        </div>
        <div className="chat-flex">
            <h2 className="chat-font">Chat</h2>
            <div className="content-chat" ref={scrollContainerRef}>
              { 
                listmessage.map((name,index) => (
                    <div className="chatreceived-flex" key={index}>
                      <div className="chattime-flex">{listpanel[index]}</div>
                      {name} <br />
                    </div>  
                ))
              } 
            </div>
            <h2 className="attendees-font">Attendees</h2>
            <div className="atttend-flex" ref={attendeesRef}>
                <div className="list-joined" >
                    {studentList.map((name,index) => (
                      <div key={index} >
                        <p>{index+1}{") "}{name}</p>
                      </div>
                    ))}
                    
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
