
import NavbarAdvance from '../components/NavbarAdvance'
import { Footer } from "../components/Footer"
import { Destinations } from "../components/Destinations"
import {Gallery} from "../components/Gallery"

function Home() {
  return (
    <div>
        <NavbarAdvance/>
        <Destinations/>
        <Gallery/>
        <Footer/>
    </div>
  )
}

export default Home