import { DestinationData } from "./DestinationData"
import "./DestinationStyles.css"

import ebrelle_brochure from "../assets/eBrelleBrochure.pdf"

import ebrelle_wan_fadil from "../assets/ebrelle_typing1.jpeg"
import ebrelle_dr_sam from "../assets/ebrelle_typing2.gif"

import ebrelle_firdaus_pertis from "../assets/ebrelle_reading_material.jpeg"
import ebrelle_firash from "../assets/ebrelle_reading_material_3.jpeg"

import ebrelle_quran_pertis from "../assets/ebrelle_quran.jpeg"
import ebrelle_dr_shaharil from "../assets/ebrelle_hold.jpeg"
import ebrelle_video from "../assets/ebrelle_video.mp4"


export const Destinations = () => {
  return (
    <div className="destination">
        <div className="first-section">
                <video src={ebrelle_video} controls autoplay alt={ebrelle_video}></video>
                <div className="text-ebrelle">
                  <h1>eBrelle</h1>
                  <p>Laptop for the Blind is an essential tool for the Blind and visually impaired people, especially students and working adults. It replaces the Braille type-writer machine and functions as a laptop </p>
                </div>
        </div>
        <div className="down-btn">
            <a
              href={ebrelle_brochure}
              download="eBrelle Brochure"
              target="_blank"
              rel="noopener noreferrer"
              alt="ebrelle brochure"
            >
            Download Brochure
            </a>
        </div>

        <DestinationData
            heading = "Typing and Storing Information"
            text = "As a laptop, it can be used to type and store information in its internal 32G memory, or in your own hard disk. It is special because unlike the Braille type-writer machine it is very quiet and is chargeable using power-bank. Therefore, you can use it in the classroom without interfering other students."
            img1 = {ebrelle_wan_fadil}
            img2 = {ebrelle_dr_sam}
            className = "first-des"
        />
       
        <DestinationData
            heading = "Access to Digital Reading Materials"
            text = "Our eBrelle is very unique because it is the only electronic Braille panel that allows you to read in Braille directly from Word, PowerPoints and PDF documents in addition to your regular .txt documents. Now you have limitless access to reading materials that are saved in digital format. Happy reading!!"
            img1 = {ebrelle_firdaus_pertis}
            img2 = {ebrelle_firash}
            className = "first-des-reverse"
        />

        <DestinationData
            heading = "Learn and Read Al-Quran"
            text = "The most important feature of eBrelle is that it comes in complete package for you to learn and read Al-Quran in Braille format. It has voice guidance which helps you to practise on your own. Plus, it also has Iqra' module for beginners and Al-Quran translation (in Malay). You don't need to wait for your teacher anymore. You can start studying and revising your Quran recitation on your own."
            img1 = {ebrelle_quran_pertis}
            img2 = {ebrelle_dr_shaharil}
            className = "first-des"
        />
    
    </div>
  )
}
