import NavbarAdvance from '../components/NavbarAdvance'
import { ContactData } from "../components/ContactData"
import { Footer } from "../components/Footer"

function Contact() {
  return (
    <div>
        <NavbarAdvance/>
        <ContactData/>
        <Footer/>
    </div>
  )
}

export default Contact