import "./Listlevel1.css"

function Listlevel1({listout, verse, level1_selected}) {
  const firstItem = Object.keys(listout);
  return (
    <div>
        {
             <ol className='listlevel1-container'>
             {
               firstItem.map((data, index)=>{
                 return(
                   <li className="listlevel1-body"
                       key ={index}>
                        <button className="listlevel1-button" onClick={() => level1_selected(data)}>
                          {data}
                        </button>
                   </li>
                 )
 
               })
             }
             </ol>
        }

    </div>
  )
}

export default Listlevel1