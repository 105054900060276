import NavbarAdvance from '../components/NavbarAdvance'
import { Footer } from '../components/Footer'
import { DonateData } from '../components/DonateData'

function Donate() {
  return (
    <div>
        <NavbarAdvance/>
        <DonateData/>
        <Footer/>
    
    </div>
  )
}

export default Donate