import "./ListModules.css"



function ListModules({listout, level, level1_selected}) {

  return (
    <div>
        {
          <ol className='list-container'>
          {
              listout.map((name, index) => {
              return(
                  <li className="list-body"
                    key ={index}
                  >
                    <button className="list-button" onClick={() => level1_selected(name)}>
                      {name}
                    </button>
                  </li>
                );
              })
          }
          </ol> 
        }
        
    </div>
  )
}

export default ListModules