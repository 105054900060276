import React from 'react'
import "./DonateData.css"


export const DonateData = () => {
  return (
    <div className="from-container">
        <br />
        <h1>A lot of blind and visually impaired students 
        <br /> who need eBrelle to help them 
        <br />learn Al-Quran, become a Hafiz and/or Qari.</h1>
        <br />
        <h1>Your donation will light up their world. 
        <br />
        <br />Let's support them by donating today!</h1>
        <h1>You may donate to:</h1>
        <p>5515 3912 3602</p>
        <p>Maybank</p>
        <p>Edote Technology Sdn Bhd</p>
        <p>Reference: eBrelle Quran</p>
    </div>
  )
}
